import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout";
import Careers from "../templates/careers/careers";


const WpPost = ({data}) => {
    const post = data;
    console.log(post)
    return (
        <Layout>
            <Careers data={post}></Careers>
        </Layout>
    )
  }
  export const query = graphql`
  {
    wpPage(slug: {eq: "careers"}) {
      date
      databaseId
      template {
        ... on WpTemplate_Careers {
          templateName
          careers {
            arrowDescription
            fieldGroupName
            ftsipDescription
            jkhmtDescription
          }
          acfdescription {
            titleFont2
            titleFont1
            fieldGroupName
            description
          }
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }
`

export default WpPost