import React, { useEffect, useState, Component } from "react";
import styled from "styled-components"
import Ratio from 'react-ratio';
import FeaturedImage from "../../components/widget/feature-image"
import useCollapse from "react-collapsed";
import {cHeight,mHeight,isMobileView, getPlatform} from "../../utils/responsiveness"
import { Description } from "../../components/widget/text";

 /**
 * 
 * @param {*} prop 
 * @returns Desktop template
 */
export function Desktop(prop) {
  const data = prop.post;
  const media = data.wpPage.featuredImage.node.mediaItemUrl;
  const post = {
    acf:{
        description_:  data.wpPage.template.acfdescription.description,
        title_font2: data.wpPage.template.acfdescription.titleFont2,
        title_font1: data.wpPage.template.acfdescription.titleFont1,
    }
  };
  if (data== null) return (<><h1>Oh no 404!</h1></>)
  

  return(
    <>  
 
      <Ratio ratio={ 16 / 9 }>
      <div style={{position: "relative",height: mHeight(4)}}>
          <div style={{zIndex: "50", position: "absolute"}}>
          <Halflengthdiv1>
            <LogoImage src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/8186_Logo-jkh-1.gif" alt="" srcset="" />
          </Halflengthdiv1>
          <Halflengthdiv2><FeaturedImage height={cHeight(80)} padding_top={"10px"} media={media} title="" style={{zIndex: "50"}}></FeaturedImage></Halflengthdiv2>
        </div>

        <Title>
          <TitleSpan> 
              <span style={{textAlign: "right",color:"#636363", fontSize:"2.6vw", fontWeight:"200"}}>
                  {post.acf.title_font1}
              </span><br />
              <span style={{textAlign: "right",color:"#2C4E6A", textTransform:"uppercase", fontSize:"2.6vw", fontWeight:"600"}}>
                  {post.acf.title_font2}
              </span>
          </TitleSpan>
        </Title>

        <SubTitle style={{backgroundColor:"white"}}>
          <span> {post.acf.description_}</span> <br />
          <Button style={{marginTop:cHeight(3)}}>
            <span>Read More</span>
          </Button>
        </SubTitle>
      </div>
      </Ratio>

      <div style={{display:"flex", flexDirection: "row", width: "75vw", margin:"auto"}}>
        <ImageContainer1>
            <Image1 src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/06/careers-image1.jpg"></Image1>
        </ImageContainer1>

        <Box>
          <span style={{fontSize: "2vw", width: "15vw", marginTop:"auto",  marginBottom:"auto",  marginLeft:"4vw", color:" #767676"}}>Latest career opportunities at John Keells Group</span>
          <Button style={{marginLeft:"4vw"}}>
            Please Visit
          </Button>
        </Box>

      </div>
      <div style={{display:"flex", flexDirection: "row", width: "100vw", margin:"auto", marginTop: cHeight(10)}}>
        <div>
        <Box2>
          <span style={{textAlign:"right", marginRight: "2vw", color:"#767676", textTransform:"uppercase", fontSize:"2vw"}}>John Keells</span>
          <span style={{textAlign:"right", marginRight: "2vw", color:"#2C4E6A", textTransform:"uppercase", fontSize:"2vw"}}>Management</span>
          <span style={{textAlign:"right", marginRight: "2vw", color:"#2C4E6A", textTransform:"uppercase", fontSize:"2vw"}}>Trainee Programme</span><br />
          <span style={{textAlign:"left", marginRight: "3vw", fontSize: "1.5vw", paddingLeft: "15vw", marginTop:"auto",  marginBottom:"auto",  marginLeft:"4vw", color:" #767676"}}>{post.acf.jkhmt_description}</span>
        </Box2>
        </div>
        <div style={{marginTop: cHeight(3)}}>
        <ImageContainer2>
            <Image2 src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/06/careers-image2.jpg"></Image2>
        </ImageContainer2>
        </div>

      </div>
      <div style={{display:"flex", flexDirection: "row", width: "100vw", margin:"auto", marginTop: cHeight(10)}}>
        <div style={{marginTop: cHeight(3)}}>
        <ImageContainer3>
            <Image3 src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/06/careers-image3.jpg"></Image3>
        </ImageContainer3>
        </div>
        <div>
        <Box3>
          <span style={{textAlign:"left", marginLeft: "2vw", color:"#2C4E6A", textTransform:"uppercase", fontSize:"2vw"}}>Fast Track</span>
          <span style={{textAlign:"left", marginLeft: "2vw", color:"#767676", textTransform:"uppercase", fontSize:"2vw"}}>Summer Internship</span>
          <span style={{textAlign:"left", marginLeft: "2vw", color:"#767676", textTransform:"uppercase", fontSize:"2vw"}}>Programme</span>
          <span style={{textAlign:"left", marginLeft: "3vw", fontSize: "1.5vw", paddingRight: "15vw", marginTop:"auto",  marginBottom:"auto",  marginLeft:"4vw", color:" #767676"}}>{post.acf.ftsip_description}</span>
        </Box3>
        </div>
      </div>
     

    </>
  );
}

const LogoImage = styled.img`
  height: ${cHeight(20)};
  padding-top: 2.6vw;
  padding-left: 2.6vw;
`

const Halflengthdiv1 = styled.div`
  width: 32vw;
  float:left;
  position: absolute;
  z-index: 50;
`

const Halflengthdiv2 = styled.div`
  width: 68vw;
  float:left;
  margin-left : 32vw;
  position: absolute;
  overflow: hidden;
  z-index: 50;
`
// Font styles
const Title = styled.div`
  @import url("https://p.typekit.net/p.css?s=1&k=mla7iff&ht=tk&f=10879.10880.10881.10882.10883.10884.10885.10886.10887.10888.15586.15587.15357.15358.15359.15360.15361.15362.15363.15364.32874.32875&a=11641042&app=typekit&e=css");

  @font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
  }
  position: absolute;
  margin-top: ${cHeight(25)};
  font-family: "futura-pt";
  width: 30vw;
  z-index: 51;
`

const para = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");

  @font-face {
    font-family: "Roboto";
    src: url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
        format("woff2"),
      url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap")
        format("woff"),
      url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap")
        format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
  }

  margin-top: 25vh;
  font-family: "futura-pt";
  width: 25vw;
  z-index: 51;
`;

const TitleSpan = styled.div`
  text-align: right;
  margin-right: 2vw;
`

const SubTitle = styled.div`
  position: absolute;
  width: 20vw;
  color: #323232;
  font-weight: 600;
  font-size: 2vw;
  font-family: "futura-pt";
  text-align: right;
  margin-left: 8vw;
  margin-right: 4vw;
  margin-top: ${cHeight(49.2)};
`

const Button = styled.div`
    height:  ${cHeight(6)};
    width: 10vw;
    background: rgb(249 249 249);
    border: 1px solid #dbd7d7;
    cursor: pointer;
    float: right;
    color: #767676;
    text-align: right;
    font-size: 1vw;
    text-transform: uppercase;
`
const ImageContainer1= styled.div`
  width: 45vw;
`

const Image1= styled.img`
  width: 45vw;
  height: ${cHeight(50)};
  margin: auto;
`
const Box= styled.div`
  margin: auto;
  margin-bottom: ${cHeight(3)};
  display: flex;
  flex-direction: column;
  width: 30vw;
  height: ${cHeight(50)};
  font-family: "Roboto";
  font-weight: 300;
`
const Box2= styled.div`
  margin: auto;
  margin-bottom: ${cHeight(3)};
  display: flex;
  flex-direction: column;
  width: 45vw;
  height: ${cHeight(50)};
  font-family: "Roboto";
  font-weight: 300;
`
const ImageContainer2= styled.div`
  width: 55vw;
  margin-top: ${cHeight(4)};
`

const Image2= styled.img`
  width: 55vw;
  height: ${cHeight(50)};
  margin: auto;
`

const Box3= styled.div`
  margin: auto;
  margin-bottom: ${cHeight(3)};
  display: flex;
  flex-direction: column;
  width: 45vw;
  height: ${cHeight(50)};
  font-family: "Roboto";
  font-weight: 300;
`
const ImageContainer3= styled.div`
  width: 45vw;
  margin-top: ${cHeight(4)};
  margin-left: 10vw;
`

const Image3= styled.img`
  width: 45vw;
  height: ${cHeight(50)};
  margin: auto;
`
