import styled from "styled-components"
import {cHeight} from "../../utils/responsiveness"

export const Title = styled.div`
@font-face {
  font-family:"futura-pt";
  src:url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display:auto;font-style:normal;<font-weight:5></font-weight:5>00;font-stretch:normal;
}
position: relative;
margin-top: ${cHeight(5)};
margin-right: 2vw;
font-family: 'futura-pt', sans-serif;
width: 25vw;
z-index: 51;
line-height: 1.1;
`

export const TitleSpan = styled.div`
font-weight: bold;
color: #5c5a5a;
text-align: center;
font-weight: 600;
text-align: end;
`

export const HeadingStyle1 = styled.div`
text-align: right;
color: #2C4E6A;
font-weight: 600;
font-size: 2.6vw ;
`
export const HeadingStyle2 = styled.div`
text-align: right;
color #636363 ;
font-size: 2.55vw;
`
export const HeadingStyle3 = styled.div`
text-align: right;
color: #929292;
font-weight: 600;
line-height: 1.1;
font-size: 2.5vw;
`

export const Description = styled.div`
word-break: 2;
/* max-width: 21ch; */
word-spacing: 0px;
height:  ${cHeight(38.3)};
position: relative;
width: 20vw;
margin-top: ${cHeight(4)};
margin-left: 5vw;
text-align: right;
color: #767676;
font-weight: 250;
font-size: 2.2vw;
font-family: 'Roboto';
border-bottom: 0px solid #c4c4c480;
/*   padding-left: 8%;
padding-top: 5%;
padding-right: 4%;
padding-bottom: 3%; */
z-index: 51;

margin-bottom: ${cHeight(0)};
`
export const Descriptionmob = styled.div`
  height: auto;
  position: relative;
  width: 100vw;
  text-align: center;
  color: #767676;
  font-weight: 200;
  font-family: 'Roboto';
  font-size: 5.8vw;
  word-break: break-word;
  margin-left: auto;
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 10%;
  z-index: 51;
  margin-bottom: ${cHeight(10)};
`